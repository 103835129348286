.socials {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  width: 97%;

}
.social-feed {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    margin-top: 0px;
    padding: 20px;
}
  
  .post {
   box-shadow: 0 2px 6px -2px rgba(23,34,96,.2);
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    padding: 1rem;
    background-color: #fff;
    height: auto;
    overflow: hidden;
}
  
.post-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
  .user-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    text-align: justify;
}
  
  .full-name {
    font-weight: bold;
  }
  
  .user-type {
    color: gray;
    font-weight: 400;
    font-size: 12px;
  }
  
  .verified {
    color: blue;
  }
  
  .post-image img {
    width: 100%;
    border-radius: 8px;
    margin-top: 1rem;
    height: 100%;
    object-fit: cover;
}
  
.post-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    border-top: solid #f9f9f9 1px;
    line-height: 43px;
}
  
  .likes-comments {
    display: flex;
    gap: 1rem;
  }
  
  .liked-by-me {
    color: green;
  }
  
  .post-count{
    display: flex;
    margin-bottom: -20px;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-evenly!important
   
  }
  .post-count p{
    color: #000;
    font-weight: 200!important;
    text-align: right;
  } 


.post-count p:first-child{
  color: #000;
  text-align: left;

} 
.likes{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.comments{
    cursor: pointer;
  
}

button.load-more {
    margin-bottom: 100px;
}
.post-image p{
    text-align: left;
    color: black;
    text-transform: capitalize;
    margin-top: 20px;
    font-weight: 300;
}
.liked {
    font-weight: 600;
}
.comment-section {
    display: flex;
    margin-top: 15px;
    border-top: solid #f9f9f9 1px;
    padding-top: 20px;
}
.comment-section button {
    height: 26px;
    float: right;
}
.comment-section {
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content: space-between;
}
.comment-section textarea{
    width: 80%;
    padding: 10px;
    background-color: #f9f9f8;
    border-radius: 10px;
}
.parent-container {
  position: sticky;
  padding: 0px;
  right: 0;
  padding-top: 40px;
  top: 85;
  width: 35%;
  margin-right: 20px;
}

.qr-section {
  display: flex;
  position: sticky;
  top: 116px;
  width: 100%;
  background-color: white;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 6px -2px rgba(23,34,96,.2);
  overflow: hidden;
}

.qr-text {
  padding: 20px;
  width: 70%;
  font-weight: bold;
}
.qr-code{
  width: 30%;
  padding: 4px;
}
.qr-code img {
  padding: 4px;
  width: 100%;
  background-color: white;
}
.play{
  width: 100%;
}
.play {
  width: 100%;
  display: flex;
  padding: 20px;
  margin-top: 0px;
  background-color: white;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
  justify-content: space-between;
  cursor: pointer;
}
.play img{
  width: 30%;
}
.play p{
  font-weight: 600;
}