/* Fonts imported */
@import url('https://fonts.googleapis.com/css2?family=Sigmar&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif !important;
}



::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: mg-bold;
  src: url(../fonts/Gilroy-Bold.woff);
}

@font-face {
  font-family: mg-extrabold;
  src: url(../fonts/Gilroy-ExtraBold.woff);
}

@font-face {
  font-family: mg-light;
  src: url(../fonts/Gilroy-Light.woff);
}

@font-face {
  font-family: mg-medium;
  src: url(../fonts/Gilroy-Medium.woff);
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  src: url(../fonts/Gilroy-Regular.woff);
}

@font-face {
  font-family: mg-semibold;
  src: url(../fonts/Gilroy-SemiBold.woff);
}



/* Common color file */
:root {
  --primary-color: #0068ff;
  --secondary-color: #273339;
  --logo-color: #0F394C;
  --bg-light-color: rgb(240, 244, 255);
  --font-light-color: rgb(97, 107, 112);
  --font-dark-color: rgb(39, 51, 57);
  --box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
  --primary-border-color: 3px solid #0F394C;
  --footer-bg: #f1f1f1;
  --cookie-color: rgb(88, 209, 189);
}





/* Header Css */


.container {
  margin-top: 80px;
}

#navTop {
  margin-top: 0px !important;
}

.mainHeader {
  background-color: red;
  padding: 30px;
  height: 25px;
}

.multigradLogo {
  width: 130%;
  float: left;
  margin-right: 40px;

}
.multigradLogoLog{
  width: 90%;
  float: left;
  margin-right: 40px;
}
.multigradLogo h1 {
  color: var(--logo-color);
  font-family: mg-extrabold;
  margin-top: 4px;
  font-size: 22px;
  margin-right: 40px;
  cursor: pointer;
  margin-left: 0px;
}
.multigradLogo img{
  width: 100%;
}

.multigradPages {
  width: 50%;
  float: left;
}

.me-auto.navbar-nav a {
  list-style: none;
  float: left;
  margin-left: 10px;
  text-align: left;
  margin-top: 0px;
  font-size: 18px;
  color: var(--logo-color);
  cursor: pointer;
  font-family: ''Montserrat', sans-serif!important';

}

.me-auto.navbar-nav a:hover {
 background-color: transparent!important;
 color:#000!important;
 font-family: 'Montserrat', sans-serif!important;
 transition: 0.2s;
 font-weight: 800;
 margin-left: -10px;
}


.authentication {
  width: 30%;
  float: left;
  margin-top: -10px;
}

.modal-backdrop.show {
  opacity: 0.8;
}



p#join {
  font-size: 20px;
  margin-top: 10px;
  font-family: ''Montserrat', sans-serif!important';
  color: var(--logo-color);
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  padding: 8px;
  width: 200px;
  border: var(--primary-border-color);
}

a#basic-nav-dropdown {
  font-family: 'mg-medium';
  color: var(--logo-color);
  font-size: 16px;
  padding-left: 50px;
}

.dropdown-menu.show {
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px 8px 20px 0px;
  width: 200px;
  margin-left: -40px;
  margin-top: 20px !important;
  border: none;
  font-family: 'Montserrat', sans-serif!important;
  font-size: 18px;
  overflow: hidden;
  padding-bottom: 0px;
  padding-left: 20px;
  width: 245px !important;
  padding-right: 23px;
}


.dropdown-item {
  height: 50px;
  padding-top: 12px !important;
}

.dropdown-item:first-child {
  margin-top: -8px;
  padding-top: 12px;
}

.dropdown-item:last-child {
  margin-top: -8px;
}

.dropdown-item:hover {
  background-color:transparent !important;
  color: #000 !important;
  width: 250px;
  margin-left: -20px;
  padding-left: 37px;
}

hr.dropdown-divider {
  border-top: 0.5pt solid grey;
}

#class {
  font-family: mg-bold;
  width: auto;
  float: right;
  margin-right: 20px;
  margin-top: 15px;
  padding: 4px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f1f1f1;
  min-width: 100px;
  cursor: pointer;
  text-align: center;
}

.auth img {
  width: 20px;
  margin-right: 10px;
  float: left;
  margin-top: 3px;
}

a#auth {
  font-size: 16px;
  font-family: 'mg-bold';
  color: #000;
  background: var(--bg-light-color);
  padding-right: 16px;
  border-radius: 10px;
  margin-top: 4px;
  border: solid var(--primary-color) 2px;
}

/* Header css ended */



/* Homescreen csF#logins started */
.mgTagline {
  width: 100%;
  border-radius: 0px;
  padding: 0px;
  float: left;
  height:auto;
  margin-top: 50px;
  padding-bottom:150px;
  padding-top: 20px;
}

.onboardinUser{
  width: 100%;
    border-radius: 0px;
    padding: 0px;
    float: left;
    height: 100vh;
    margin-top:40px;
    padding-bottom: 0px;
    padding-top: 20px;
    background-color: #fff;
}

.onboardinUser h1{
  font-size: 100px;
    text-align: center;
    color: #16192c;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
}

.mgTagline h1{
  font-size: 28px;
  text-align: center;
  color:#16192c;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}


.tagLeft {
  float: left;
  width: 55%;
}

.tagLeftPrivacy {
  float: left;
  width: 80%;
}

.mgTagline p {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  margin-top: 20px;
  width: 100%;
  float: left;

}
 .join p{
    color: white;
}
.mgTagline img {
  width: 45%;
  float: left;
}

#bold {
  cursor: pointer;
  font-family: mg-extrabold;
}

.joinUs {
  width: 100%;
  float: left;
  margin-top: -20px;
}

.joinUs label {
  width: 100%;
  float: left;
  padding: 10px;
  margin-top: -40px;
  font-family: mg-bold;
  margin-left: -10px;
  color: var(--logo-color);
  font-size: 30px;
}

.joinUs input {
  width: 40%;
  margin-left: 0%;
  padding: 15px;
  border: var(--primary-border-color);
  border-radius: 12px;
  margin-top: 0px;
  font-family: 'Montserrat', sans-serif!important;
  font-size: 20px;
  border: solid 0.5 black !important;
  background-color: var(--bg-light-color);
}

.joinUs button {
  width: 80px;
  margin-left: 0%;
  padding: 15px;
  border: var(--primary-border-color);
  border-radius: 12px;
  margin-top: -70px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 16px;
  font-family: 'Montserrat', sans-serif!important;
  background: var(--logo-color);
  font-size: 20px;
  cursor: pointer;
  color: #fff;
  margin-left: 4px;
}

#profile {
  width: 30px;
  height: 30px;
  float: left;
  border-radius: 100%;
  margin-right: -40px;
}



/* signin popup */
.modal-content {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 16px;
  padding: 40px;
  font-family: 'Montserrat', sans-serif!important;
  height: 80vh;
}

.modal-content input {
  height: 45px;
  border: solid var(--logo-color) .5pt;
  color: var(--logo-color);
}

button.col-12.customPopupBtn.btn.btn-danger {
  background-color: #000;
  border: none;
  height: 45px;
  border-radius: 14px!important;
}

button.col-12.withGoogle.btn.btn-primary {
  height: 45px;
  background-color: var(--bg-light-color);
  color: var(--primary-color);
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.Email {
  background-color: #0068ff !important;
  color: #fff !important;
}

button.col-12.withGoogleHome.btn.btn-primary {
  width: 250px;
  background-color: var(--bg-light-color);
  height: 45px;
  background-color: var(--bg-light-color);
  color: var(--primary-color);
  border: none;
  cursor: pointer;
}

p#privacy {
  font-size: 12px;
  margin-top: 50px;
  text-align: center;
}

#gIcon {
  font-size: 22px;
  margin-top: -4px;
  margin-right: 13px;
}

#forgot {
  margin-top: 18px;

  cursor: pointer;
}

#redirectDashboard {
  font-family: mg-bold;
}

svg#icon {
  font-size: 20px;
  margin-right: 8px;
  margin-top: 0px;
}



/* footer part started */

.footer {
  float: left;
  margin-top: 50px;
  background-color: rgb(5, 0, 56);
}

.nb-footer {
  margin-top: 0px;
  float: left;
  padding: 80px;
  padding-top: 20px;
}

.footerContainer {
  width: 100%;
  margin-top: 0px;
  float: left;
  padding-top: 50px!important;
  overflow: hidden;
  padding: 80px;
    padding-top: 20px;
  background: #f9f9f9;
}
.footer-one{
  width: 25%;
  float: left;
}
.footer-one h2{
  font-family: 'Montserrat', sans-serif!important;
  font-size: 18px;
  margin-bottom: 10px;
}
.footer-one a{
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  float: left;
  margin-top: 5px;
}
.footer-one h1{
  font-family: mg-extrabold;
  margin-bottom: 20px;
  color:#0F394C;
}
.copyright {
  text-align: center;
  background-color: transparent;
  font-family: 'Montserrat', sans-serif!important;
  float: left;
  width: 100%;

}
.social-icons a{
  width: 30px;
}
.copyright p{
  color: #000;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
}

.copy {
  background-color:#f9f9f9;
  color: white;
  width: 100%;
  float: left;
  margin-top: -80px;

}

#tagline {
  font-family: mg-extrabold;
  font-size: 30px;
  color: white;
}

footer {
  font-family: 'Montserrat', sans-serif;
  color: white !important;

}

.socialSites li {
  float: left;
  margin-left: 20px;
  color: white !important;
  color: pointer;
}

.socialSites li:first-child {
  margin-left: 0px;
}

.title {
  font-size: 20px;
  font-family: mg-extrabold;
  margin-top: 10px;
  opacity: 0.5;
  color: white;
}

.list-unstyled a {
  text-decoration: none;
  font-size: 18px;
  margin-top: 10px;
  float: left;
  width: 300px;
  color: white !important;
  font-family: 'Montserrat', sans-serif!important;
}

.CookieConsent {
  background: #fff !important;
  height: 100px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px 8px 20px;
}

#iconCookie {
  color: #050038 !important;
  ;
  border-radius: 100px;
  font-size: 50px;
  width: 10%;
  float: left;
}

#text {
  color: #0F394C;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  width: 80%;
  float: left;
}

button#rcc-confirm-button {
  color: #050038 !important;
  ;
  margin-top: -50px !important;
  float: left;
  padding: 10px !important;
  color: #fff !important;
  font-family: 'Montserrat', sans-serif!important;
}



/* Feed or Dashboard css */
.feed {
  background-color: #fff;
  float: left;
  width: 100%;
  height: 100vh;
}

.leftMenu {
  width: 20%;
  padding: 10px;
  float: left;
  position: sticky;
  top: 0px;
  background-color: #fff;
}

.rightMenu {
  width: 25%;
  padding: 10px;
  float: left;
  background-color: #fff;
  max-height: 400px;
  overflow: hidden;
}

.centerMenu {
  width: 55%;
  float: left;
  padding: 10px;
  border-radius: 10px;
}

.leftMenu .Banner img {
  width: 108.5%;
  background-color: #f1f1f1;
  height: 100px;
  float: left;
  margin: -9px;
  object-fit: contain;
}

.leftMenu .userProfile {
  width: 100%;
  float: left;
}

.leftMenu .userProfile img {
  width: 40%;
  margin-top: -20px;
  border-radius: 100%;
  margin-left: 30%;
  background-color: #fff;
  width: 80px;
  object-fit: contain;
  height: 80px;

}

.leftMenu .userName {
  width: 100%;
  color: var(--logo-color);
  font-family: mg-bold;
  text-align: center;
  float: left;
  padding: 10px;
  height: 30px;

}

.leftMenu .userAbout {
  width: 100%;
  color: var(--logo-color);
  font-family: 'Montserrat', sans-serif!important;
  text-align: center;
  float: left;
  padding: 10px;
  height: auto;

}

.userButton {
  width: 100%;
  float: left;
}

.userButton p {
  padding: 6px;
  background-color: #f1f1f1;
  text-align: center;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif!important;
  cursor: pointer;
}

.rightMenu h2 {
  font-size: 14px;
  width: 80%;
  float: left;
  font-family: mg-bold;
  padding: 10px;
}

#all {
  width: 20%;
  float: left;
  cursor: pointer;
  margin-top: 14px;
}

.userFollowClass {
  width: 100%;
  float: left;
  padding: 10px;
}

.userFollowClass .profile {
  width: 20%;
  float: left;
}

.userFollowClass .profile img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 100%;
}

.userFollowClass .fullName {
  width: 50%;
  float: left;
  padding: 4px;
  height: 30px;
  font-family: mg-bold;
  color: var(--logo-color);
  margin-left: 0px;
  margin-top: 19px;
}

.userFollowClass .fullName p {
  font-size: 14px;
  height: 20px;
  line-height: 0.8rem;
  margin-top: -19px;
}

.userFollowClass .fullName p:last-child {
  font-size: 14px;
  color: var(--secondary-color);
  opacity: 0.8;

}

.userFollowClass .button {
  width: 20%;
  float: left;
}

.userFollowClass .button #button {
  background-color: var(--bg-light-color);
  color: var(--primary-color);
  padding: 10px;
  border: none;
  height: 35px;
  width: 70px;
  font-size: 12px;
  border-radius: 4px;
  font-family: mg-bold;
  margin: 10px;
  margin-top: 4px;
  text-align: center;
  cursor: pointer;
}



@media (prefers-color-scheme: dark) {
  body {
    background-color: #1f2023;
    color: #dadada;
  }

  h1 {
    color: black;
    margin-top: 40px!important;;
    font-weight: 700!important;;
    font-size: 28px!important;;
  }
}

button#rcc-confirm-button {
  background: #050038 !important;
  border-radius: 0px !important;
  width: 200px;
}

.br {
  width: 84%;
  margin-left: 7%;
  height: 0.1pt;
  background-color: #f1f1f1;
  float: left;
  margin-bottom: 20px;
}

div#example-collapse-text {
  float: left;
  background: white;
  padding: 80px;
  font-family: 'Montserrat', sans-serif!important;
  padding-top: 40px;
}

.tagRight {
  width: 40%;
  float: left;
}

.tagRight img {
  width: 90%;
  margin-left: 5%;

}

.playstore {
  float: left;
  width: 30%;
  margin-top: 20px;
  padding: 8px;
  border-radius: 14px;
  margin-left: 10px;
  text-align: center;
  border: solid #000 1px;
  background-color:#000;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

.down {
  margin-left: 0px;
  background-color: #f0f0f0;
  color: rgb(5, 0, 56);
  border:none;
  font-weight: 500;

}

.playstore img {
  width: 60%;
  margin-top: 20px;
}

#apple {
  font-size: 22px;
}

.qrModal .modal-content {
  border-radius: 30px;
  border: none;
  margin-top: 150px;
  width: 120%;
  height: auto;
  margin-left: -5%;
}

.modalLeft h1 {
  font-family: 'mg-extrabold';
  color: var(--logo-color);
  font-size: 30px;
  width: 100%;
}

.modal-header {
  border: none !important;
}

.modalLeft {
  width: 70%;
  padding: 20px;
  float: left;
  padding-left: 0px;
}

.modalRight {
  width: 30%;
  padding: 20px;
  float: left;
  padding-left: 0px;
}

.modalLeft p {
  font-family: 'Montserrat', sans-serif!important;
  font-size: 20px;
}

.modalLeft #apple {
  font-size: 40px;
  color: var(--logo-color);
  margin-top: 20px;
  margin-left: 20px;
}

.left {
  margin-left: 0px !important;
}

.modalRight img {
  float: left;
  width: 130%;
  margin-top: 20px;
  margin-left: -20px;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
  padding: 0px;
}

#navTop {
  margin-top: 0px !important;
  width: 110%;
  float: left;
}

.mgTagline #trust {
  text-align: center;
  color: rgba(5, 0, 56, .6);
  font-family: 'Montserrat', sans-serif!important;
}

.institutes {
  float: left;
  margin-top: 0px;
  width: 90%;
  margin-left: 3%;
}

.institutes img {
  width: 70px;
  object-fit: contain;
  height: 100px;
  margin-left: 120px;
  float: left;
  mix-blend-mode: luminosity;

}

.institutes img:hover {
  mix-blend-mode: normal;
  cursor: pointer;

}

a#link {
  color: #0068ff !important;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif!important;
}

.network {
  background-color: #ECF0F3;
  overflow: hidden;
  padding-bottom: 55px;
}

.tagLeft img {
  width: 90%;
  float: left;
  margin-bottom: -70px;
}

.join {
  background-image: url(../../assets/media/bg.png);
  border-radius: 0px;
  padding-top: 100px;
  margin-bottom: -40px;
  text-align: center;
  width: 500%;
  float: left;
  left: 0;
  background-color: #0068ff;
  margin-left: -200%;
}

.join h1 {
  width: 100%;
  text-align: center;
  font-family: mg-extrabold;
  color: #fff;
}

.join button {
  background-color: white;
  border: none;
  padding: 10px;
  width: 200px;
  margin-top: 20px;
}

.socialSites a {
  float: left;
  width: 15%;
}

.leftSide {
  width: 20%;
  background-color: white;
  float: left;
  padding: 20px;
  margin-left: -65px;
  height: 100vh;
  margin-top: -20px;
  position: fixed;
}

.menu {
  width: 89%;
  float: left;
  padding: 10px;
  height: 50px;
  margin-left: 17px;
  margin-bottom: 20px;
}

.menu p {
  font-size: 20px;
  font-family: 'Montserrat', sans-serif!important;
  cursor: pointer;
}

.menu .home {
  margin-top: 40px;
  float: left;
}

.profile {
  cursor: pointer;
}

.menu img {
  float: left;
  margin-right: 20px;
  cursor: pointer;
  width: 25px;
  height: 25px;

}

.menu p {
  margin-left: 0px;
  float: left;
}

.timeLine {
  width: 60%;
  float: left;
  margin-left: 16.6%;
  margin-top: -15px;
  position: sticky;
  position: -webkit-sticky;
  top: 200;
  padding: 10px;
  background-color: white;
  height: 100%;
  z-index: 99;
  margin-top: 0px;
  border-left: 1px solid rgb(240, 240, 240);
}

.timeLine h1 {
  font-size: 28px;
  color: #050038;
  font-family: mg-extrabold;
}

.profile img {
  border-radius: 100%;
}

.menu.home.active {
  background: #ECF0F3;
  border-radius: 100px;
  padding-left: 20px;
  padding-right: 30px;
  width: auto;
  margin-left: 10px;
  padding-top: 12px;
}

.post {
  background-color: #0068ff;
  padding: 15px;
  height: 60px;
  text-align: center;
  margin-top: 20px;
  border-radius: 50px;
}

.post p {
  text-align: center;
  width: 100%;
  color: #fff;
  font-family: mg-extrabold;

}

.heading {
  position: fixed;
  top: 0;
  margin-top: -10px;
  height: 87px;
  background-color: transparent;
  width: 55.5%;
  border-bottom: 1px solid rgb(240, 240, 240);
  margin-left: -20px;
  padding-top: 30px;
  z-index: 999;
  padding-bottom: 20px;
}

.heading p {
  width: 50%;
  float: left;
  text-align: center;
  font-family: mg-bold;
  margin-top: 20px;
  color: #273339;
  font-size: 18px;
}

.menu.home {
  margin-top: 63px;
}

.userPost {
  float: left;
  background-color: #fff;
  border-radius: 0px;
  padding: 40px;
  width: 100%;
  margin-top: 3px;
  border-top: solid #f1f1f1 2px;
}

.userPostHeading p {
  font-family: mg-bold;
  font-size: 22px;
  color: #000;
}

.userPostAnswer p {
  font-family: 'Montserrat', sans-serif!important;
  color: #000;
  font-size: 22px;
  text-transform: capitalize;
}

.userDetails {
  width: 100%;
  float: left;
  margin-top: 0px;
  padding-top: 0px;
}

.userDetails .userProfile {
  width: 12%;
  float: left;
}

.userDetails .userProfile img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  object-fit: contain;
}

.userDetails .userName {
  font-family: mg-bold;
  width: 80%;
  font-size: 17px;
  float: left;
  margin-left: -20px;


}

.userName p {
  margin-top: 4px;
  width: 30%;
  float: left;
}

.userName p:first-child {
  width: auto;
  margin-right: 5px;
}

#time {
  font-size: 14px;
  opacity: 0.7;
  margin-top: 8px;
  font-family: 'Montserrat', sans-serif!important;
}

#status {
  width: 100%;
  margin-top: -15px;
  font-family: 'Montserrat', sans-serif!important;
  font-size: 14px;
}

.tp {
  margin-top: 10px;
}

.more {
  cursor: pointer;
  text-align: right;
}

.userContenr {
  float: left;
  padding: 40px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-left: 25px;
  padding-bottom: 60px;
}

svg#start {
  position: absolute;
  margin-top: -36px;
  margin-left: -78px;
  font-size: 76px;
  color: #f1f1f1;
}

div#create-post .modal-content {
  border-radius: 20px;
  border: none;
  width: 140%;
  margin-left: -14%;
  height: auto;
}

#create-post img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 100%;
}

#create-post input#exampleForm\.ControlInput1 {
  border: navajowhite;
  font-size: 22px;
  color: #333;
  font-family: ''Montserrat', sans-serif!important';
}

.form-control:focus {
  border: none !important;
  box-shadow: none !important;
}

#create-post #name {
  font-size: 22px;
  font-family: 'Montserrat', sans-serif!important;
  margin-left: 20px;
  margin-top: 8px;
}

#your_answer {
  height: 100px;
}

input#exampleForm\.ControlInput1.cust {
  height: 76px;
  font-family: 'Montserrat', sans-serif !important;
}

.modal-footer {
  border: none;
}

#create-post button.btn.btn-primary {
  width: 110px;
  padding: 9px;
  border: none;
  border-radius: 100px;
}

div#basic-navbar-nav {
  margin-left: 0px;
}

@media only screen and (max-width: 1200px) {
  body {
    padding: 20px;
    overflow-x: hidden;
  }

  div#basic-navbar-nav {
    background: linear-gradient(105deg, #DFEBFD 0%, #E4F4EC 100%);
    padding: 33px;
    margin-left: -44px;
        height: 100vh;
    padding-top: 0px;
  }

  .join {
    display: none;
  }

  button.navbar-toggler.collapsed {
    border: none;
    color: black;
  }

  span.navbar-toggler-icon {
    color: black !important;
    margin-right: 0px;
  }

  .tagLeft {
    float: left;
    width: 100%;
  }

  .tagLeft.mobile {
    margin-top: -100px;
  }


  .playstore {
    width: 55%;
    float: left;
  }

  .down {
    width: 40%;
  }

  .tagRight {
    width: 100%;
    float: left;
    margin-top: 20px;
  }

  .network {
    background-color: #ECF0F3;
    overflow: hidden;
    padding-bottom: 55px;
    padding: 26px;
    border-radius: 0px;
    width: 120%;
    margin-left: -10%;
  }

  .tagLeft img {
    width: 100%;
    float: left;
    margin-bottom: -40px;
    margin-top: 0px;
  }

  .tagRight img {
    width: 90%;
    margin-left: 5%;
    display: none;
  }

  .footerContainer {
    width: 110%;
    margin-left: -20px;
    margin-top: 40px;
    float: left;
    padding-top: 0px;
    background-color: rgb(5, 0, 56);
    padding-right: 40px;
  }

  .qrModal .modal-content {
    border-radius: 30px;
    border: none;
    margin-top: -20px;
    width: 120%;
    margin-left: -5%;
    border-radius: 0px;
    height: auto;
    top: 0;
  }
}

img#circle {
  width: 150%;
  margin-bottom: -50px;
  margin-left: -105px;
  margin-top: -112px;

}

.modal-content {
  overflow: hidden;
}

body {
  overflow-x: hidden;
  width: 100%;
  /* background: linear-gradient(100deg, #DFEBFD 9.6%, #E4F4EC 61.61%); */
}


/* dharm css */
.course {
  width: 100%;
  display: flex;
  height: auto;
  margin-top: -15px;
}

.app-container {
  width: 15%;
  padding: 20px;
  background-color: #f5f5f5;
  position: fixed;
  height: 100vh;
}

.content-container {
  width: 85%;
  margin-left: 15%;
  background-color: #fff;
  height: 100%;
  border-radius: 100px;
  padding: 50px;
  padding-left: 30px;
}

.bg-surface-secondary {
  background-color: #fff !important;
}

.user-profile-div {
  float: right;
  padding: 10px;
  background-color: red;
  height: 92vh;
  width: 25%;
  position: sticky;
}

.sidebar-nav .nav-link {
  color: #333;
  margin-top: 50px;
}

.sidebar-nav .nav-link:hover {
  background-color: #ddd;
}

a.link {
  margin-top: 20px;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif!important;
  color: #1f2023;
}

a.link:first-child {
  margin-top: 80px;
}

a.link:hover {
  color: #000;
}

@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    height: auto;
    left: 0;
  }

  .content-container {
    order: -1;
    padding-top: 60px;
    width: 100%;
    background-color: #F4F7FE;
    height: auto;
  }
}


nav.navbar.navbar-expand-lg.navbar-light {
  height:80px;
  position: relative;
  width: 100%;
  padding: 0px;
  top: 0;
  margin-top: 0px;
  position: fixed;
  box-shadow: 0 0 40px 0 rgba(0,0,0,.05);
  background-color: white;
}

.link {
  text-decoration: none;
}

.custom-card {
  margin-bottom: 0;
}

@import url(https://unpkg.com/@webpixels/css@1.1.5/dist/index.css);

/* Bootstrap Icons */
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");



/* Job Section */
.mg-job-container {
  background-color: white;
  width: 100%;
  height: 400px;
  margin-top: 80px;
  padding: 0px;
  padding-top: 0px;
}

.mg-job-first-section {
  background-color: #212226;
  padding: 140px;
  padding-top: 20px;
}

.mg-job-first-section-all {
  background-color: #212226;
  padding: 60px;
  padding-top: 20px;
  padding-bottom: 50px;
}

.mg-job-first-section h1 {
  font-family: mg-bold;
  color: white;
  margin-top: 40px;
  font-size: 34px;
}

.mg-job-first-section p {
  font-size: 22px;
  color: white;
  font-family: 'Montserrat', sans-serif!important;
}

.mg-job-second-section {
  background-color: white;
  width: 50%;
  margin-top: -100px;
  margin-left: 8%;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px;
  padding-bottom: 100px;
  margin-bottom: 100px;
  float: left;
}

.mg-job-second-section h2 {
  font-family: mg-bold;
  font-size: 28px;
}

.job-input-sections {
  width: 100%;
  margin-top: 20px;
}

.job-input-sections label {
  width: 100%;
  font-family: 'Montserrat', sans-serif!important;
  font-size: 14px;
}

.job-input-sections input {
  width: 100%;
  background-color: #F0F0F0;
  padding: 4px;
  margin-top: 0px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 4px;
  font-size: 12px;
  height: 35px;
}

.job-input-sections textarea {
  width: 100%;
  height: 80px;
  background-color: #F0F0F0;
  padding: 4px;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 4px;
  font-size: 14px;
}

.job-input-sections select {
  width: 100%;
  background-color: #F0F0F0;
  margin-top: 13px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 4px;
  font-size: 14px;
  color: #000;
  padding-left: 20px;
  padding: 4px;

}


#special-note {
  font-size: 10px;
  font-family: 'Montserrat', sans-serif!important;
  padding: 0px;
  color:blue;
}

.mg-job-container button {
  width: 200px;
  padding: 6px!important;
  background-color: #000;
  color: white;
  font-family: 'Montserrat', sans-serif!important;
  border-radius:10px!important;
  float: left;
  margin-top: 50px;
}

#select-file {
  width: 300px;
  height: 50px;
  overflow: visible;
  padding: 10px;
}

.mg-jobs-listings {
  width: 100%;
  padding: 60px;
  padding-top: 40px;
}

.mg-jobs-single-listings {
  width: 30%;
  float: left;
  margin: 10px;
  border-radius: 10px;
  border: 0.2px solid #F0F0F0;
  background: #FFF;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.06);
  padding: 15px;
  padding-bottom: 0px;
  min-height: 210px;

}

.mg-jobs-company-details {
  float: left;
  width: 100%;
}

.mg-jobs-company-details img {
  width: 50px;
  height: 50px;
  float: left;
  border-radius: 4px;
}

.mg-jobs-job-details {
  float: left;
  width: 100%;
}

.live-and-edit {
  float: left;
  width: 100%;
}

.mg-jobs-company-details h3 {
  float: left;
  margin-left: 20px;
  font-size: 18px;
  font-family: mg-bold;
  width: 75%;
}

.mg-jobs-company-details p {
  float: left;
  margin-left: 20px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif!important;
  width: 75%;
  color: rgba(0, 0, 0, 0.50);
  font-family: 'Montserrat', sans-serif!important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mg-jobs-job-details h3 {
  width: 100%;
  float: left;
  font-family: 'Montserrat', sans-serif!important;
  font-size: 18px;
  margin-top: 20px;
}

.mg-jobs-job-details p {
  width: 100%;
  float: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  margin-top: 0px;
}

#live {
  background-color: #F9F9F9;
  width: auto;
  float: left;
  margin: 2px;
  padding: 2px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  border-radius: 2px;
}

#delete {
  width: 20%;
  float: left;
  text-align: left;
  margin-top: 20px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  background-color: red;
  text-align: center;
  border-radius: 2px;
  border: solid red 1px;
}

#edit {
  border: solid black 0.3px;
  text-align: center;
  border-radius: 2px;
  width: 20%;
  float: left;
  margin-right: 10px;
  margin-top: 20px;
  color: #000;
  font-size: 12px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.dropdown-menu.show {
  display: block;
  margin-left: -86px;
}


#qr {
  text-align: center;
  margin-top: 20px;
}

#qrimg {
  width: 50%;
  margin-left: 25%;
}

.qrDiv {
  padding: 20px;
  width: 100%;
  border: 3px dashed #f0f0f0;
  border-radius: 10px;
}

body {
  overflow-x: hidden !important;
}

#signup {
  font-family: 'Montserrat', sans-serif!important;
  margin-right: 20px;
  float: right;
  cursor: pointer;
  margin-top: 15px;
  padding: 10px;
  cursor: pointer;
}

.cb-page {
  background-color: #0068ff;
  width: 100%;
  float: left;
  height: auto;
  padding-bottom: 100px;
  background-image: url(../../assets/media/bg.png);
}

.cb-page h1 {
  color: #FFFFFF;
  font-family: mg-bold;
  margin: 40px;
  margin-top: 100px;
  font-size: 70px;
}

.cb-page p {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  color: #fff;
  margin-left: 40px;
  margin-top: -10px;
}

.cb-input {
  background-color: #fff;
  width: 30%;
  margin-left: 40px;
  padding: 10px;
  float: left;
  border: solid black 2px;
  border-radius: 100px;
  overflow: hidden;
  height: 50px;
  margin-top: 50px;
}

.cb-input input {
  width: 60%;
  background-color: #fff;
  padding: 12px;
  margin-top: -10px;
  font-family: 'Montserrat', sans-serif;
  float: left;
}

.cb-input button {
  background-color: #000;
  float: right;
  width: 40%;
  font-size: 16px;
  margin-top: -12px;
  margin-right: -10px;
}

.cb-input input:focus {
  outline: none;
}

.cb-page h2 {
  width: 100%;
  float: left;
  padding: 20px;
  color: #fff;
  font-family: mg-bold;
  margin-left: 20px;
  margin-top: 60px;
  font-size: 44px;
}

#top {
  float: left;
  margin-top: -250px;
  margin-left: 800px;
}

.mg-peoples {
  margin: 20px;
}

.mg-profiles {
  background-color: #fff;
  margin: 20px;
  float: left;
  width: 20%;
}

.mg-profiles button {
  width: 80%;
  float: left;
  margin-left: 10%;
  margin-bottom: 10px;
  background-color: white;
  border: solid black 2px;
  height: 38px;
  color: black;
  padding-top: 5px;
  font-family: mg-bold;
  margin-top: 10px;
}

.mg-profiles p {
  color: #273339;
  font-family: 'Montserrat', sans-serif!important;
  float: left;
  margin: 0px;
  font-size: 14px;
  text-align: center;
  width: 100%;
  margin-top: 10px;

}

.mg-profiles img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

#share {
  display: none;
}


#add {
  width: 50px;
  font-size: 40px;
  padding: 0px;
  padding-top: 0px;
  background-color: #fff;
  color: black;
}

.courses {
  width: 90%;
}

#removeItem {
  width: 50px;
  font-size: 40px;
  padding: 0px;
  padding-top: 0px;
  background-color: #fff;
  color: black;
  position: absolute;
  margin-top: -170px;

}

.ql-toolbar.ql-snow {
  border: 0px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  background: #f0f0f0;
  border-radius: 4px 4px 0px 0px;
}

.ql-container.ql-snow {
  background: #f0f0f0;
  border: none;
  border-top: solid black 1px;
  border-radius: 0px 0px 4px 4px;
  height: 200px;
}

.ql-toolbar.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  border: none;
  border-top: solid #0068ff 1px;
}

#tableOfContent {
  align-items: center;
  justify-content: center;
  width: 500px;
}

#tableOfContent .modal-content {
  width: 200%;
  margin-left: -52%;
}

#tableOfContent th {
  font-size: 18px;
  width: 30%;
  font-weight: 900;
  padding: 5px 20px;
}

#tableOfContent td {
  font-size: 18px;
  padding: 5px 20;
}


#note {
  width: 100%;
  float: left;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif!important;
  margin: 10px;
}

#choose-banner {
  margin-top: 20px;
}

#disable {
  background-color: #dadada;
}

#banner {
  height: 180px;
  width: 50%;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  object-fit: contain;
  position: absolute;
  left: 0;
  opacity: 0;
  margin-left: 10.8%;
}

#defaultBanner {
  height: 180px;
  width: 39%;
  object-fit: contain;
  position: absolute;
  left: 0;
  margin-left: 10.8%;
  z-index: 0;
  margin-top: 20px;
}

#removeItemCourse {
  position: absolute;
  right: 0;
  margin-right: 6%;
  margin-top: -470px;
  background-color: transparent;
  color: red;
  font-size: 30px;
}

input#username {
  background: #f9f9f9;
  border: none;
}

input#password {
  background: #f9f9f9;
  border: none;
}

#margin {
  margin-top: 20px;
}



/* portfolio */
.main-container {
  width: 100%;
  justify-content: center;
  display: flex;
  background-color: #EBEFF3;
}

.home-div {
  width: 100%;
  background-color: white;
  float: left;
  box-shadow: 0 1.267198085784912px 1.267198085784912px #666d8014, 0 5.068792343139648px 10.137584686279297;
  border-radius: 10px;
  margin: 20px;
  padding: 30px;
  float: left;
  margin-top: 60px;
}

.userName-active {
  width: 100%;
  float: left;
}

.userName-active h1 {
  color: #363849;
  width: 60%;
  float: left;
  font-size: 20px;
  font-weight: 500;
}

#active {
  width: 100%;
  float: left;
  margin-top: 10px;
  margin-right: 10px;
  font-family: 'Montserrat', sans-serif;
}

.userName-active p {
  background-color: #D0FADF;
  width: 30%;
  float: left;
  text-align: center;
  padding: 4px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 100px;
  color: #049753;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 14px;
}

.name-profile {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.name-profile-left {
  width: 70%;
  float: left;

}

.name-profile-left h2 {
  color: #363849;
  font-size: 36px;
  font-family: mg-bold;

}



.name-profile-right {
  width: 100%;
  float: left;
}

.name-profile-right img {
  width: 200px;
  float: left;
  padding: 5px;
  border: solid #f9f9f9 6px;
  border-radius: 20px;
  padding: 0px;
  margin-top: 40px;
}

.name-profile-left p {
  color: #666D80;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.name-profile-left a {
  margin-left: 5px;
  text-decoration: none;
}

#darkBtn {
  background-color: #000;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0px 1px 1px 0px rgba(102, 109, 128, 0.08), 0px 4px 8px 0px rgba(102, 109, 128, 0.2), 0px 0px 0px 0px var(--token-17aea52f-75cc-4210-9cfa-54c6a6e98e8e, rgb(255, 255, 255)), 0px 0px 0px 0px rgba(53, 56, 73, 0.16);
  opacity: 1;
  color: white;
  padding: 10px;
  float: left;
  width: 100px;
  border: none;
  cursor: pointer;
  border: solid #000;
}

.btn {
  margin-top: 40px;
  width: 100%;


}

#darkIcon {
  float: left;
  opacity: 0.6;
}

#lightBtn {
  background-color: #fff;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0px 1px 1px 0px rgba(102, 109, 128, 0.08), 0px 4px 8px 0px rgba(102, 109, 128, 0.2), 0px 0px 0px 0px var(--token-17aea52f-75cc-4210-9cfa-54c6a6e98e8e, rgb(255, 255, 255)), 0px 0px 0px 0px rgba(53, 56, 73, 0.16);
  opacity: 1;
  color: #000;
  padding: 10px;
  float: left;
  width: 120px;
  border: solid #f9f9f9;
  cursor: pointer;
  margin-left: 10px;
}

.projects {
  background-color: #F6F8FA;
  width: 100%;
  margin-top: 40px;
  float: left;
  margin-left: -3.5%;
  padding: 20px;
  padding-top: 20px;
  border-radius: 10px;
}

.ListOfProjects {
  width: 93%;
  float: left;
  box-shadow: 0px 1px 1px 0px rgba(102, 109, 128, 0.08),
    0px 4px 8px 0px rgba(102, 109, 128, 0.08),
    0px 0px 0px 0px var(--token-17aea52f-75cc-4210-9cfa-54c6a6e98e8e, rgb(255, 255, 255)),
    0px 0px 0px 3px rgba(53, 56, 73, 0);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 15px;
  height: 60px;
  padding-top: 15px;
}

.projectImg {
  width: 50px;
  float: left;
  height: 50px;
  background-color: white;
  padding: 5px;
  border-radius: 100px;
  border: solid #f9f9f9 2px;
}

.projectName {
  width: 60%;
  float: left;
  padding-left: 20px;
}

.projectView {
  width: 19%;
  float: left;
  justify-content: right;
}

#overview {
  font-size: 16px;
  color: rgb(102, 109, 128);
  font-weight: 400;
  margin-top: -10px;
}

.projectImg img {
  width: 100%;
  float: left;
  border-radius: 100px;
  object-fit: contain;
}

#name {
  font-weight: 600;
  color: rgb(53, 56, 73);
  font-size: 18px;
  margin-top: 5px;
}

#next {
  font-size: 30px;
  color: #f9f9f9;
  text-align: right;
  float: right;
  margin-top: 15px;
  opacity: 0.2;
}

.connect {
  margin-top: 40px;
  width: 100%;
  float: left;
  text-align: center;
}

.connect h3 {
  font-size: 40px;
  color: rgb(53, 56, 73);
}

.connect p {
  color: rgb(102, 109, 128);
  margin-top: -20px;
  float: left;
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
}

.btnCnt {
  display: flex;
  justify-content: center;
  float: left;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 60px;
}

.social {
  background-color: rgb(246, 248, 250);
  padding: 20px;
  float: left;
  width: 94%;
  margin-top: 40px;
  border-radius: 8px;
  margin-bottom: 100px;
}



.skills img {
  width: 80px;
  float: left;
}

#type {
  font-weight: 700;
}


#iconsSocial {
  font-size: 30px;
  color: #1f1f1f;
  margin-left: 35px;
}

@media screen and (max-width: 1200px) {
  .home-div {
    width: 100%;
    background-color: white;
    float: left;
    box-shadow: 0 1.267198085784912px 1.267198085784912px #666d8014, 0 5.068792343139648px 10.137584686279297;
    border-radius: 10px;
    margin: 0px;
    padding: 20px;
  }

  .userName-active {
    width: 100%;
    float: left;
  }

  .userName-active h1 {
    color: #363849;
    width: 100%;
    float: left;
    font-size: 20px;
    font-weight: 500;
  }

  .userName-active p {
    width: 50%;
    float: left;
    margin-left: 0px;
  }

  .name-profile {
    margin-top: 0px;
  }

  #lightBtn {
    background-color: #fff;
    height: 100%;
    border-radius: 6px;
    box-shadow: 0px 1px 1px 0px rgba(102, 109, 128, 0.08), 0px 4px 8px 0px rgba(102, 109, 128, 0.2), 0px 0px 0px 0px var(--token-17aea52f-75cc-4210-9cfa-54c6a6e98e8e, rgb(255, 255, 255)), 0px 0px 0px 0px rgba(53, 56, 73, 0.16);
    opacity: 1;
    color: #000;
    padding: 10px;
    float: left;
    width: 120px;
    border: solid #f9f9f9;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 0px;
  }

  .projects {
    padding: 10px;
    background-color: #fff;
  }

  .ListOfProjects {
    width: 89%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 0px;
  }
}

.setting-page {

  width: 70%;
  margin-left: 15%;
  margin-top: 50px;
  float: left;
}

.setting-menu {
  width: 20%;
  float: left;
}

.setting-menu p {
  font-family: 'Montserrat', sans-serif!important;
  cursor: pointer;
}

.setting-content {
  float: left;
  width: 80%;
  margin-bottom: 50px;
}

.top-menu {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.top-menu-profile {
  float: left;
  width: 10%;
  padding: 0px;

}

.top-menu-profile img {
  border-radius: 100%;
  width: 100px;
  height: 90px;
  object-fit: cover;
}

.top-menu-fullname {
  float: left;
  width: 90%;
  margin-top: 10px;
}

.top-menu-fullname h1 {
  font-family: mg-bold;
  font-size: 28px;
  margin-left: 20px;
  color: #050038;
}

.top-menu-fullname p {
  font-family: 'Montserrat', sans-serif!important;
  font-size: 18px;
  margin-left: 20px;
  color: #050038;
}

.menu-inputs {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.one {
  margin-top: 0px;
}

.menu-inputs label {
  width: 100%;
  font-family: 'Montserrat', sans-serif!important;
}

.menu-inputs input {
  width: 80%;
  float: left;
  background-color: #f9f9f9;
  height: 45px;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif!important;
  padding-left: 20px;
  margin-top: 10px;
  float: left;
}

.menu-inputs input:focus,
.menu-inputs input:hover {
  border: none;
  outline: none;

}

.menu-inputs p {
  float: left;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  margin-top: 5px;
  font-size: 12px;
}

#url {
  font-family: mg-bold;
}

.save-Changes {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.save-Changes button {
  float: right;
  margin-right: 20%;
  background-color: #0068ff;
  padding: 10px;
  width: 150px;
  border-radius: 100px;
  color: #fff;
  font-family: 'Montserrat', sans-serif!important;
  font-size: 14px;
  margin-top: 30px;
}

#bio {
  height: 100px;
  width: 80%;
  background-color: #f9f9f9;
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
}

.menu-inputs select {
  width: 80%;
  height: 45px;
  background-color: #f9f9f9;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
}

.userProfile {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 100px;
  margin-right: -40px;
  float: right;
}

.peoples {
  width: 100%;
  float: left;
}

#approved{
  background-color: transparent!important;
  margin-right: 10px;
  color:#050038!important;
  font-family: mg-bold;
  margin-left: -20px!important;
}

.peoples h1 {
  color: #0F394C;
  font-family: mg-bold;
  font-size: 28px;
  margin-top: 40px;
}

.peoples table {
  border: solid #f9f9f9 2px;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
}

.peoples table tr:nth-child(even) {
  background-color: #fff;
}


.peoples table tr {
  margin: 0px;
  float: left;
  width: 100%;
    background-color: #f9f9f9;
}

thead tr th,thead tr  {
  background: #f0f0f0 !important;
}

.peoples table td {
  min-width: 200px;
  font-family: 'Montserrat', sans-serif!important;
  padding: 10px;
}

.peoples table th {
  min-width: 200px;
  font-family: mg-bold;
  background-color: #f9f9f9;
  padding: 10px
}

.skills-section {
  float: left;
  width: 97%;
  border: solid #f9f9f9 4px;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.skills-section img {
  float: left;
  width: 5%;
}

.skills-section p {
  float: left;
  width: 80%;
  margin-left: 8%;
  margin-top: -22px;
  font-family: 'Montserrat', sans-serif!important;
  color: black;
}

.skills-list {
  float: left;
  width: auto;
  padding: 2px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  margin: 2px;
}


.two {
  background-color: rgb(251, 186, 160)
}

.three {
  background-color: rgb(192, 192, 240);
}

.four {
  background-color: rgb(182, 192, 240);
}

.five {
  background-color: rgb(192, 192, 20);
}

.six {
  background-color: rgb(192, 192, 200);
  color: white
}

.seven {
  background-color: rgb(192, 192, 240);

}

.eight {
  background-color: rgb(178, 235, 192);
}

.nine {
  background-color: rgb(251, 186, 160)
}

.ten {
  background-color: rgb(178, 235, 192);
}

.eleven {
  background-color: rgb(192, 192, 240);
}

.tweleve {
  background-color: rgb(251, 186, 160)
}

.skills-list a {
  color: #000;
}

/* DASHBOARD PAGE CSS*/

.dashboard-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 40px;
  height: auto;
  margin-top: 40px;
  padding-bottom: 80px;
  padding-top: 20px;
}

.dash-details {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-top: 50px;
}

.dashboard-wrapper h3 {
  margin-top: 50px;
  font-family: 'Montserrat', sans-serif!important;
}

.dash-details h5 {
  margin-right: 15px;
  font-family: 'Montserrat', sans-serif;
  border-right: 1px solid black;
  cursor: pointer;
}

.dash-qr {
  margin-top: 30px;
  width: 80%;
  padding: 30px;
  display: flex;
  flex-direction: row;
  background-color:transparent;
  border: dashed #f9f9f9 2px;
  border-radius: 10px;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif!important;
}

.dash-text {
  font-size: 18px;
  margin-right: 20px;
  font-weight: 450;
}

.dash-qr img {
  height: 150px;
}

.dash-btn {
  width: 50%;
  border-radius: 0% !important;
}

.dash-premium {
  margin-top: 30px;
  width: 80%;
  padding: 30px;
  display: flex;
  flex-direction: row;
  background-color: #fbf8f4;
  border: dashed 2px #ef8766;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif!important;
}

.dash-link {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dash-link button {
  padding: 9px;
  margin: 0px;
}

.dashlinkurl {
  background-color: white;
  padding: 10px;
  margin: 0px;
}

.profile {
  margin-top: 50px;
}

.profile img {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  padding: 20px;
  margin-left: -20px;
  margin-top: -20px;
}
.profile h4{
  font-family: mg-bold;
  margin-bottom: 50px;
}
.navs ul {
  list-style: none;
  padding: 0px;
}

.navs ul li a {
  text-decoration: none;
  font-family: 'Montserrat', sans-serif!important;
  color: #000;
  font-size: 18px;
  cursor: pointer;
}

.msg-toggler {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  padding-left: 20px;
  border-bottom: solid #f9f9f9 2px;
  height: 40px;
  margin-top: 5px;
}

.msg-toggler button {
  background: white;
  height: 20px;
}
.msg-toggler h4 {
  font-size: 1.5rem;
  text-align: left;
  width: 94%;
  margin-left: 15px;
  font-family: mg-bold;
  font-size: 22px;
  margin-top: 2px;
  color:black;
}
.msg-toggler img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.messenger-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 350px;
  margin-bottom: 0px;
  margin-right: 20px;
  padding: 10px;


  margin: 0px;
  background-color:#fff ;
  box-shadow: 0px -4px 40px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px 10px 0px 0px;
}

.messenger-container ul {
  height: 500px;
}

/*MESSENGER CHATS CSS*/

.userchat {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 0px;
  margin: 5px;
  height: 60px;
  border-bottom: solid #f1f1f1 1px;
}

.userchat:hover {
  background-color:#f9f9f9;
}

.userchat img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
}
.userchat h5{
  font-family: mg-bold;
  color:black;
  font-size: 16px;
  margin-top: -10px;
  float: left;
  width: 100%;
}
.userchat p{
  height: 0px;
  margin-top: -8px;
  float: left;
  opacity: 0.7;
  width: 100%;
  font-family: 'Montserrat', sans-serif!important;
  font-size: 12px;
}
.chats-container{
  height: 450px;
  overflow: scroll;
}

.chat {
  background-color: #f9f9f9;
  border-radius: 10px;
  height: 450px;
}

.chat-info{
  background-color: #fff;
  padding: 10px;
  border-bottom: solid #fff 1px;
}
.chat-info h5{
    color: #1f1f1f;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif!important;
}

.chat-info h5 img{
  height: 30px;
  border-radius: 50%;
  margin: 0px 10px;
  color: #000;
}
.chat-info h5 svg {
  color: black !important;
}
.msg-container{
  height: 345px !important;
  overflow: scroll;
  padding:10px;
  display: flex;
  flex-direction: column-reverse;
}

.msg-owner{
  display: flex;
  background: black;
    margin-top: 0;
    margin-right: 35px;
    margin-bottom: 1rem;
    padding: 5px;
    color:white;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Montserrat', sans-serif;
    max-width: max-content;
    flex-direction: row;
    border-radius:0px 10px 10px 10px;
}

.msg{
  display: flex;
  background-color:#0068ff;/* #D0FADF*/
  margin-top: 0;
  color: white; /*black*/
  margin-left: 35px;
  margin-bottom: 1rem;
  padding: 5px;
  align-self: flex-end;
  border-radius:10px 0px 10px 10px;
}

.msg-text{
  margin-bottom: 0px;
   padding-left: 15px;
   padding-right: 15px;
   font-family: 'Montserrat', sans-serif;
   font-size: 14px;
}


.input-container {
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  overflow: hidden;
  justify-content: center;
  padding: 5px;
  bottom: 0;
  margin-bottom: 0px;
  width: 195%;
  align-content: flex-end;
  margin-top: -150px;
}

.input-page{
  width: 100% !important;
}

.input-container input{
  outline: none;
  font-size: 16px;
  padding:5px ;
  width: 91%;
  background-color: #f1f1f1;
  float: left;
  padding-left: 10px;
  padding: 20px;
  height: 150px;
  margin-left: 0px;
  margin-bottom: 0px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 20px;
}

.chat-input-btn{
  width: 20% !important;
  margin-top: 0px;
  font-family: 'Montserrat', sans-serif;
  padding: 8px !important;
  border-radius: 0px !important;
  background-color: #0068ff!important;
  font-size: 12px!important;
  border-radius: 100px!important;
}
#activeNav a{
  font-size: 24px;
  font-family: mg-bold;
  margin-top: 30px;
  margin-bottom: 20px;

}

.custom-dash h3{
  font-family: mg-bold;
}
.custom-dash p {
  font-family: 'Montserrat', sans-serif;
}
#bsplus{
  background-color: #0068ff;
  margin-right: 10px;
  color: white;
  border-radius: 100px;
}
.no-message-found{
  text-align: center;
  font-family: 'Montserrat', sans-serif!important;
  margin-top: 50%;
}
.dashboard-wrapper.container{
  margin-top: 70px;
}

#editCampus{
  font-size: 12px;
  color:#0068ff;
  /* border: solid #0068ff 0.1px; */
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  margin-left: 10px;
  background-color: rgba(20, 106, 255, .1)
}

.organisation{
  padding: 60px;
  float: left;
  width: 100%;
  padding-top: 50px;
}

.organisation h1{
  color: #0F394C;
  font-family: mg-bold;
  font-size: 28px;
}

.organisation-overview{
  
  background-color: white;
  float: left;
  overflow: hidden;
  width: 40%;
  margin-left:60px;
  padding: 20px;
  margin-top: -20px;
  border-radius: 4px;
  box-shadow: 0px -4px 40px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 50px;
}


#orgImg{
  width: 100px;
  border-radius: 2px;
  height: 100px;
}
.org-details{
  width: 110%;
  float: left;
  flex-direction: row;
  border-bottom:solid #f0f0f0 1px;
  margin-top: -20px;
    margin-left: -20px;
    margin-bottom: 20px;
}

.org-details img{
  width: 40px;
  height: 40px;
  padding: 10px;
  float: left;
  margin: 10px;
}

.org-details p{
  float: left;
  margin-left: 10px;
  margin-top: 20px;
  font-family: mg-bold;
}

.signup-page{
  width: 40%;
 margin-left: 30% ;
  background: white;
  padding: 60px;
  height: auto;
  margin-bottom: 50px;
  margin-top:140px;
  border-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(0,0,0,.05);
}

.signup-page h1{
  font-family: mg-bold;
  text-align: center;
  color: #000;
  font-size: 22px;
}
.signup-page p {
  font-family: 'Montserrat', sans-serif!important;
  text-align: center;
  color: #000;
}

.signup-page label{
  font-family: 'Montserrat', sans-serif!important;
    color: #000;
    margin-top: 10px;
    margin-bottom: 4px;
}

.signup-page input {
  height: 50px;
  font-family: 'Montserrat', sans-serif!important;
  border-radius: 0px!important;


}

.signup-page button{
  background-color: #0068ff;
  margin-top: 40px;
  padding: 10px;
  color: white;
  border-radius: 100px;
  font-family: 'Montserrat', sans-serif!important;

}

#picUpload{
  width: 100px;
  height: 100px;
  position: absolute;
  margin-top: 0px;
  border-radius: 0px;
  opacity: 0;
  cursor: pointer;
}

#digital{
  font-family: 'Montserrat', sans-serif!important;
  background-color: white;
  margin-right: 20px;
}

.create-post-form{
  background-color: rgba(0, 0, 0, 0.3);
}

.create-post-form .modal-content{
  box-shadow: none!important;
  padding: 20px;
 border-radius: 20px;
  width: 120%;
  margin-top: 80px;
  margin-left: -10%;
  padding-top: 0px;
  height: auto;
}
#postQuestion{
  width: 100%;
 float: left;
 border: none;
 font-family: 'Montserrat', sans-serif!important;
 height: 60px;
}

 .modalBody input:focus,
.modalBody input:hover {
  outline: none!important;
  border: none!important;
}

#postAnswer{
  width: 100%;
  border-radius: none;
    float: left;
border: none;
    font-family: 'Montserrat', sans-serif!important;
    height: 100px;
}

.userDetails{
  float: left;
  width: 60%;
  float: left;
  background-color: #fff;

}
.userDetails img{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  float: left;
}
.userDetails select{
  margin-left: 20px;
  width: 120px;
  float: left;
  background-color: red;
  padding: 4px;
  background-color: #f9f9f9;
  border-radius: 100px;
  padding-left: 20px;
  text-transform: capitalize;
  padding-right: 20px;
  font-family: 'Montserrat', sans-serif!important;
  color: #000;
  margin-left: 50px;
  float: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: -10px;
}
#modalIcon{
  font-size: 20px;
  float: left;
  text-align: left;
  margin-bottom: 20px;
}
.mobileDown{
  display: none;
}
.desktopDown{
  display: block;
}
#publishBtn{
  float: right;
  width: 15%;
  margin-top: 30px;
  background-color: #0068ff;
  padding: 6px;
  color: white;
  border-radius: 20px;

}

.modalBottom{
  margin-top: 20px;
  float: left;
  width: 100%;
  border-top: solid #f4f4f4 1px;
  padding-top: 20px;
}
.modalBottom img{
  width: 80px;
  height: 80px;
  border-radius:4px;
  cursor: pointer;
}

.modalBottom input {
  width: 80px;
  height: 80px;
  position: absolute;
  margin-left: -80px;
  opacity: 0;
}

#defaultImg{
  width: 30px;
  height: 30px;
}

#closeBtn{
  background-color: #fff;
  margin-bottom: 20px;
  float: left;
}
.userDetails  p{
  float: left;
  width: 70%;
  margin-left: 20px;
  font-weight: 500;
}
#pD p{
  font-size: 16px;
  line-height: 5px;
}
#pD p:nth-child(2) {
  font-size: 12px;
}
#sB button{
  background-color: #0068ff;
  width: 100px;

  padding: 4px;
  font-size: 12px;
  color: white;
  border-radius: 100px;
}
#rj{
  margin-left: 10px;
  background-color: red!important;
}


#managePeople{
  font-family: 'Montserrat', sans-serif;
}
.filters{
  margin-top: 40px;
  width: 100%;
  float: left;
}

.filters select{
  padding: 8px !important;
  width: 200px;
  border: solid #f9f9f9 2px;
  border-radius: 8px;
  margin-right: 20px;
  font-family: 'Montserrat', sans-serif!important;
}
.filters input {
  padding: 8px !important;
    width: 200px;
    border: solid #f9f9f9 2px;
    border-radius: 8px;
    margin-right: 20px;
    font-family: 'Montserrat', sans-serif!important;
}
.filters button {
  padding: 8px !important;
  padding: 10px !important;
    width: 200px;
    color:#050038;
    border-radius: 8px;
    margin-right: 20px;
    background-color: #fff;
    font-family: 'Montserrat', sans-serif!important;
}
.filters h1{
  font-family: mg-bold;
  color: #0F394C;
}

button{
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 100px!important;
}

/*MessengerPage CSS*/
.msg-page-wrapper {
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 10px;
  padding-top: 10px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  height: 96vh;
}

.messenger-page-toggler{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.messenger-page-section-1{
  height:82vh;
  overflow: scroll;
}

.messenger-page-section-2{
  background-color:#f4f4f4;
}
.messenger-page-chats{
    height: 100vh;
}

.msg-container-com{
  height: 72% !important;
  overflow: scroll;
  padding:10px;
  background-color: #fff;
  display: flex;
  flex-direction: column-reverse;
  border: solid #f1f1f1 1px;
  border-radius: 10px;
}

.msg-container-page{
  height: 72vh !important;
  overflow: scroll;
  padding:10px;
  display: flex;
  flex-direction: column-reverse;
}

.msg-community {
  display: flex;
    margin-top: 0;
    margin-right: 35px;
    margin-bottom: 1rem;
    padding: 5px;
    color:white;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Montserrat', sans-serif;
    max-width: max-content;
    flex-direction: row;
    border-radius:0px 10px 10px 10px;
}

.msg-community p{
  margin: 0px;
  padding: 0px;
}

.msg-community img{
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.msg-content-wrapper{
  margin-top: 0;
    margin-right: 35px;
    margin-bottom: 1rem;
    padding: 5px;
    color:black;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Montserrat', sans-serif;
    max-width: max-content;
    border-radius:0px 10px 10px 10px;
}

.switchCommunity {
  float: left;
  width: 100%;
}
.switchCommunity button{
  width: 50%;
  background-color: transparent;
  font-size: 20px;
  font-family: mg-bold;
  color: #000;
}


#activeClass{
border-bottom: solid black 2px;
border-radius: 0px!important;
}
.emptyInbox{
  float: left;
  background-color: #fff;
  border: solid #f1f1f1 1px;
  width: 100%;
  height: 100vh;
  padding: 200px;
}

.emptyInbox h2{
  font-family: mg-bold;
  color: #0F394C;
  float: left;
  width: 100%;
}

.emptyInbox p{
  font-family: 'Montserrat', sans-serif!important;
  color: #0F394C;
  float: left;
  width: 100%;
  padding: 0px;
}

.certificate-table th{
  width: 80% !important;
  text-align: center;
}


.tableofmembers .modal-content{

  width: 138% !important;
  margin-left: -17% !important;
}
.staticHeader{
  position: relative;
  top: 0;
  width: 100%;
  background: #0068ff;
  padding: 0px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  font-family: 'Montserrat', sans-serif;
  height: 30px;
}
.staticHeader a{
  font-family: mg-bold;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.staticHeader a:hover {
color: rgba(255,255,255,0.8);
}

.me-auto.navbar-nav a{
  font-family: 'Montserrat', sans-serif!important;
  font-size: 16px;
}
.dropdown-menu.show {
background: white;
  border-radius: 0px;
  box-shadow: none;
  margin-left: 0px;
  margin-top: 0px !important;
  text-align: left !important;
  padding: 0px;
}

.main-container{
  width: 100%;
  float: left;
  padding: 60px;
  margin-top: 80px;
}
.main-container h1 {
  font-family: 'Montserrat', sans-serif!important;
  color: #000;
  width: 60%;
  float: left;
  margin-left: 0%;
  text-align: center;
}
.mgTagline h2{
  font-size: 40px;
  color: #050038;
  text-align: left;
  font-weight: 900;
font-family: 'Montserrat', sans-serif;
  margin-top: 80px;
  width: 70%;
}
.navbar-light .navbar-brand {
  color: #16192c;
  width: 10%;
}
.container {
  padding: 0px;
  margin-top: 0px;
}


.features {
  background: linear-gradient(107deg, #DFEBFD 9.6%, #E4F4EC 61.61%);
  padding: 5px;
  height: 80px;
  margin-top: 0px;
  border-radius: 0px;
  margin-bottom: 100px;
  position: sticky;
  top: 0;
  margin-left: -6%;
  width: 116%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.features p{
  float: left;
  width: auto;
  margin-left: 40px;
  cursor: pointer;
  font-weight: 400;
}
.activeFeature {
  background-color: #0068ff;
  padding: 6px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  margin-top:15px!important;
  float: left;
  border-radius: 100px;
  font-size: 14px!important;
  transform: rotate(-4deg);
  height: 35px;
}

.visible-feature-div img{
  width: 60%;
}
.visible-feature-left,.visible-feature-right{
  float: left;
  width: 50%;
}
.visible-feature-right h2{
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 400;
  border-bottom: solid #f9f9f9 30px;
  line-height: 10px;
  text-align: left;
}

.visible-feature-right button{
  background-color: #0068ff;
  font-family: 'Montserrat', sans-serif !important;
  color: #fff;
}

.modal-title.h4{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 24px;
}

.navs{
  background: linear-gradient(280deg, #fff 0%, #DFEBFD 350%);
  padding: 20px;
  width: 200px;
}
.me-auto.header.navbar-nav{
  float: right;
  width: 70%;
  justify-content: flex-end;
  margin-top: 15px;
}

#create-post{
  background-color: #0068ff;
  color: white;
  font-size: 20px;
  border-radius: 100px;
  margin-right: 4px;
  margin-top: 2px;
}

.header-item{
  flex-direction: row;
  width: 100px;
  display: flex;

}
#signup
{
  font-weight: 600;
  font-size: 14px;
  background-color: #000;
  padding: 8px;
  width: 180px;
  color:white;
  text-align: center;
  margin-left: 10px;
  border-radius: 14px;
}

p#signin {
  font-weight: 600;
    font-size: 14px;
    background-color: #f9f9f9;
    padding: 8px;
    width: 140px;
    color: #000;
    text-align: center;
    margin-left: -20px;
    border-radius: 14px;
    margin-top: 15px;
}

a.nav-link{
  font-weight: 500!important;
  margin-left: 20px!important;
  font-size: 16px!important;
}

#dropdown-basic{
  float: left;
  margin-top:10px;
  font-weight: 500 !important;
  border-radius: 0px!important;
  margin-left: 10px;
}

#dropdown-basic:focus {
  box-shadow: none;
  border: none;
}


#dropdown-basic:active {
  box-shadow: none;
  border:none;
}

#dropdown-basic:hover {
  box-shadow: none;
  border: none;
}

.dropdown-menu.show {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.04);
  background: #fff;
  padding-left: 0px!important;
  border-radius: 4px;
  padding-top: 10px;


}
a.dropdown-item{
  font-weight: 500;

}

#footer-head{
  font-weight: 700;
  font-size: 18px;
}

#about{
  width:100%;
  margin-bottom: 40px;
}

.footer-seo{
  width: 100%;
  float: left;
  margin-bottom: 40px;
  margin-left: -10px;
}
.footer-seo a{
  margin-left: 0px;
  float: left;
  color: black;
  text-decoration: none;
  font-size: 10px;
  border-right: solid rgba(0,0,0,0.2) 1px;
  padding-right: 10px;
  margin-left: 10px;
}

#category{
  font-weight: 600;
  float: left;
  width: 100%;
}

.categories{
  width: 100%;
  float: left;
}

.search-bar{
  border: solid #f0f0f0 1px;
  padding: 0px;
  border-radius: 200px;
  overflow: hidden;
  width: 400px;
  flex-direction: row;
  display: flex;
  padding-left: 20px;
  padding-right: 4px;
  margin-left: 80px;
}
.search-bar input{
  padding: 10px;
  border:none;
  width: 70%;
  font-size: 12px;
}
.search-bar input:hover, .search-bar input:active,.search-bar input:focus-visible{
  outline:none;
  border:none;
}

.search-bar button{
  color: white;
  background-color: #0068ff;
  margin-left: 20px;
  font-size: 12px;
  width: 30%;
}

#categories{
  float: left;
  margin-top: 80px;
  padding: 20px;
  position: relative;
  z-index: 0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.04);
  height: 40px;
  background-color: white;
  
}
.list-cate{
  display: flex;
  flex-direction: row;
  width: 80%;
  float: left;
 display: flex;
 flex-direction: row;
  margin-left: 150px;
  justify-content: end;
  float: right;
}
.cate-icons {
  width: 100%;
  float: left;
}
#categories span{
  font-weight: 700;
  font-size: 14px;
  margin-left: 10px;
}
.list-cate a{
  font-size: 14px;
  margin-left: 20px;
  font-weight: 500;
  cursor: pointer;
} 
p#about a {
  font-size: 0px !important;
}

.navbar-expand-lg .navbar-brand img {
  cursor: pointer;
}


.me-auto.navbar-nav {
  margin-left: 50px;
}
.container {
  margin-top: 80px;
}
.uploadbanner{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0px;
}

.uploadbanner button{
  background-color: #0068ff;
  height: 35px;
}
.msg-page-wrapper.container {
  margin-top: 100px;
  height: 700px;
  overflow: hidden;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 10px;
}
p.text-underline {
  text-decoration: none!important;
  font-weight: 600;
}

.input-page button.btn.btn-dark.chat-input-btn {
  margin-top: 10px;
  float: right;
  width: 10%;
  margin-left: 70%;
}
.input-page {
  width: 100%;
  background: white;
  padding-bottom: 40px;
  margin-bottom: 20px;
}
a.nav-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.card{
  border: none!important;
  box-shadow:  rgba(0, 0, 0, 0.1) 0px 0px 20px;
  border-radius: 10px;
}
button.btn.btn-primary {
  font-size: 14px!important;;
}
.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  font-size: 18px!important;;
}
.space40 {
  float: left;
  width: 100%;
  height: 40px;
}
.table thead th{
  color: white;
}
.space{
  width: 40%;
  margin-top: 50px;
  margin-bottom: 100px;
}


.static-row.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.static-row.row .col {
  min-width: 19% !important;
  margin: 2px;
  max-width: 19%;
  margin-left: 2px !important;
  margin-top: 25px;
}
button.col-12.customPopupBtn.send-again {
  background: transparent;
  color: black;
  color: blue;
  font-size: 14px;
  margin-top: -101px;
  text-align: right;
  font-weight: 500;
}
.dropzone {
  width: 40%;
  background: #f9f9f9;
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 22px;
  cursor: pointer;
}
#red{
  color:red;
}
